<template>
  <div class="banner_box">
    <el-carousel height="380px">
      <el-carousel-item autoplay v-for="(item, k) in banner" :style="{ 'background-image': 'url(' + item.img + ')' }"
        :key="k" class="banner">
        <a v-if="item.url" :href="item.url" class="banner-nav" target="_blank"></a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      banner: [],
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.$fecth.post("banner/getdata").then((res) => {
        this.banner = res.lists;
      });
    },
  },
};
</script>

<style scoped lang='less'>
.banner_box {
  min-width: 1200px;
  position: relative;

  .banner {
    background-position: center;
    background-size: cover;

    .banner-nav {
      display: block;
      width: 100%;
      height: 400px;
    }
  }
}
</style>
