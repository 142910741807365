<template>
  <div class="countdown">
    <div class="content">
      <div class="title">·{{ year }}年高考·</div>
      <div class="day">
        <div>倒计时</div>
        <div>{{ time.day | filterDay }}<span>天</span></div>
      </div>
      <div class="second">
        <span>{{ time.hou }}</span>时<span>{{ time.min }}</span>分<span>{{ time.sec }}</span>秒
      </div>
    </div>

    <!-- <div class="content1">
    </div> -->
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      actEndTime: "",
      year: '',
      time: {
        day: 0,
        hou: 0,
        min: 0,
        sec: 0,
      },
    };
  },
  filters: {
    filterDay(value) {
      if (String(value).length < 2) {
        return '00' + value
      }
      if (String(value).length < 3) {
        return '0' + value
      }
      else {
        return value
      }
    }
  },

  // ''
  created() {


    this.countDown();
  },
  methods: {
    //倒计时

    countDown() {
      this.getTime()
      setInterval(() => {
        this.getTime();
      }, 1000);
    },
    getTime() {
      let str = '/06/07 09:00:00'
      const nowTime = new Date().getTime();
      this.actEndTime = new Date().getFullYear() + str
      this.year = new Date().getFullYear()
      if (new Date(this.actEndTime).getTime() - nowTime < 0) {
        this.actEndTime = new Date().getFullYear() + 1 + str
        this.year = new Date().getFullYear() + 1
      }
      const newTime = new Date().getTime(); // 对结束时间进行处理渲染到页面
      const endTime = new Date(this.actEndTime).getTime();
      const time = (endTime - newTime) / 1000; // 获取天、时、分、秒
      const day = parseInt(time / (60 * 60 * 24));
      const hou = parseInt((time % (60 * 60 * 24)) / 3600);
      const min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
      const sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
      this.time.day = this.timeFormats(day);
      this.time.hou = this.timeFormat(hou);
      this.time.min = this.timeFormat(min);
      this.time.sec = this.timeFormat(sec);
    },

    timeFormat(param) {
      return param < 10 ? "0" + param : param;
    },
    timeFormats(param) {
      if (param < 100) {
        param = param < 10 ? "0" + param : param
        if (param < 10) {
          param = param < 10 ? "0" + param : param
        }
      }
      return param;
    },
  },
};
</script>

<style scoped lang='less'>
.countdown {
  display: inline-block;
  border-left: 20px solid #f6f6f6;
  width: 194px;
  vertical-align: top;
  height: 230px;
  padding: 39px 50px;

  .content1 {
    background: url("../../../assets/img/home/clock3.jpg") no-repeat;
    height: 240px;
  }

  .content {
    transform: scale(1.1);
    background: url("../../../assets/img/home/home-bg.png") no-repeat 0 -550px;
    position: relative;
    top: 9px;
    left: 4px;

    .title {
      text-align: center;
      color: white;
      padding: 6px 0;
    }

    .day {
      padding: 46px 29px 20px;

      div:first-child {
        width: 19px;
        font-size: 13px;
        display: inline-block;
      }

      div:last-child {
        font-weight: 700;
        width: 100px;
        display: inline-block;
        vertical-align: top;
        margin-top: 2px;
        font-size: 48px;
        margin-left: 5px;
        letter-spacing: 1px;

        span {
          font-size: 12px;
        }
      }
    }

    .second {
      margin: 10px 0;
      height: 50px;
      text-align: center;
      font-size: 12px;

      span {
        font-weight: 700;
        font-size: 25px;
        position: relative;
        top: 3px;
      }
    }
  }
}
</style>
