<template>
  <div class="main">
    <div class="title">热门推荐</div>
    <el-divider></el-divider>
    <div class="hot">
      <router-link to="goodsInfo?id=2">
        <div class="left"></div>
      </router-link>
      <div class="right">
        <a href="
https://basic.smartedu.cn/syncClassroom?defaultTag=e7bbcefe-0590-11ed-9c79-92fc3b3249d5%2F6a749654-0772-11ed-ac74-092ab92074e6%2F44bee8bc-54e6-11ed-9c34-850ba61fa9f4%2Feae5dc6a-147b-11ed-ab76-54d6327ace99"
          target="target">
          <div class="right_1"></div>
        </a>
        <router-link to="goodsInfo?id=5">
          <div class="right_2"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
};
</script>

<style scoped lang='less'>
.hot {
  .left {
    display: inline-block;
    width: 600px;
    height: 300px;
    background: url("../../../assets/img/home/r_1.png") no-repeat;
  }

  .right {
    display: inline-block;
    vertical-align: top;
  }

  .right_1 {
    width: 489px;
    height: 145px;
    margin-bottom: 11px;
    background: url("../../../assets/img/home/linkbg.jpg") no-repeat;
  }

  .right_2 {
    height: 144px;
    background: url("../../../assets/img/home/home-bg.png") no-repeat -1000px -400px;
  }
}
</style>
