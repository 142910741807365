<template>
  <div class="main">
    <div class="title">新闻动态</div>
    <el-divider></el-divider>
    <div class="new">
      <div class="box_n" v-for="(item, i) in newsList" :key="i">
        <div class="title_n" v-if="i == 0">高考资讯</div>
        <div class="title_n" v-else-if="i == 1">高校新闻</div>
        <div class="title_n" v-else-if="i == 2">数据看板</div>
        <div class="title_n" v-else>新闻详情</div>
        <div class="img_n img_n_1" v-if="i == 0"></div>
        <div class="img_n img_n_2" v-else-if="i == 1"></div>
        <div class="img_n img_n_3" v-else-if="i == 2"></div>
        <div class="img_n img_n_4" v-else></div>
        <div class="new_title" v-if="i !=2" v-html="item.brief"></div>
        <div class="new_title" v-else>通过数据看板您可以快速清晰的了解到一些与高考志愿填报相关的数据，我们选用数据排行的形式简明扼要的让您获取基础信息，这些信息每年都会依照最新数据进行更新。</div>
        <div class="foot_n">
          <router-link v-if="i !=2" :to="{ path: 'NewsList?type=' + item.type}">查看更多》
          </router-link>
          <router-link v-if="i ==2" :to="{ path: '/lookData'}">查看更多》
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      newsList: [],
    };
  },
  
  
  created() {
    this.getNews();
  },
  methods: {
    //获取新闻
    getNews() {
      this.$fecth
        .post("news/getNewList", {
          pageSize: 4,
        })
        .then((res) => {
          this.newsList = res;
        });
    },
  },
};
</script>

<style scoped lang='less'>
.new {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;

  .box_n {
    width: 22%;
    border: 1px solid #dcdfe6;
    padding: 20px 10px 13px;

    .title_n {
      color: #4475fa;
      font-size: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
    }

    .img_n {
      width: 100%;
      margin: 10px 6px;
      height: 163px;
    }

    .img_n_1 {
      background: url("../../../assets/img/home/home-bg.png") -150px 0;
    }

    .img_n_2 {
      background: url("../../../assets/img/home/home-bg.png") -450px 0;
    }

    .img_n_3 {
      background: url("../../../assets/img/home/home-bg.png") -750px 0;
    }

    .img_n_4 {
      background: url("../../../assets/img/home/home-bg.png") -1050px 0;
    }

    .new_title {
      height: 48px;
      padding: 0 20px;
      font-size: 12px;
      color: #6b6c6c;
      text-indent: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -ms-flexbox;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .foot_n {
      text-align: right;
      margin-top: 15px;

      a {
        color: #8e8f90;
        font-size: 12px;
      }
    }
  }
}
</style>
