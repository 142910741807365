<template>
  <div class="main">
    <div class="title">热门专业
      <!-- <div class="nav"> <span @click="change(1)" :class="{hove:nav == 1}">文科</span> <span @click="change(2)" :class="{hove:nav == 2}">理科</span></div> -->
      <div class="more">
        <router-link to='/lookData'>更多 ></router-link>
      </div>
    </div>
    <el-divider></el-divider>

    <div class="content">
      <el-row>
        <el-col :span='5' v-if="nav == 1">
          <el-row class="name" style="margin-top:50px">
            法学
          </el-row>
          <el-row class="school" style="margin-top:30px">
            报考人数：2329人
          </el-row>
          <router-link :to="{
       path: '/specialtyInfo',
                query: { sid: '030101K', degree: 0 },
    }"> <img class="college" src="@/assets/img/home/major.png" alt=""></router-link>

        </el-col>

        <el-col :span='5' v-else>

          <el-row class="name" style="margin-top:50px">
            计算机类
          </el-row>
          <el-row class="school" style="margin-top:30px">
            报考人数：8665人
          </el-row>
          <router-link :to="{
                path: '/specialtyInfo',
                query: { sid: '080901', degree: 0 },
              }"> <img class="college" src="@/assets/img/home/maj0.jpg" alt=""></router-link>

        </el-col>
        <el-col :span='18' :offset="1">
          <div class="list">
            <div v-for="(item,i) in dataList" :key="i" class="piece">
              <router-link :to="{
                path: '/specialtyInfo',
                query: { sid: item.specialty_code, degree: 0 },
              }">
                <el-row class="name">
                  {{item.name}}
                </el-row>
                <el-row class="school">
                  报考人数：{{item.baokao}}人
                </el-row>
                <img class="t_img" :src="imgList[i]['img']">
              </router-link>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import data from "@/assets/data/hotMajor.js";
import { mapState } from "vuex";
export default {
  name: "",
  ...mapState(["userInfo"]),
  data() {
    return {
      dataList: [],
      pageNum: 1,
      pageSize: 6,
      nav: 1,
      imgList: [],
      imgList2: [
        { img: require("./../../../assets/img/home/mj1.png") },
        { img: require("./../../../assets/img/home/mj2.png") },
        { img: require("./../../../assets/img/home/mj3.png") },
        { img: require("./../../../assets/img/home/mj4.png") },
        { img: require("./../../../assets/img/home/mj5.png") },
        { img: require("./../../../assets/img/home/mj6.png") },
      ],
      imgList1: [
        { img: require("./../../../assets/img/home/maj1.jpg") },
        { img: require("./../../../assets/img/home/maj2.jpg") },
        { img: require("./../../../assets/img/home/maj3.jpg") },
        { img: require("./../../../assets/img/home/maj4.jpg") },
        { img: require("./../../../assets/img/home/maj5.jpg") },
        { img: require("./../../../assets/img/home/maj6.jpg") },
      ],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    if (this.userInfo) {
      if (this.userInfo.is_wenli == 1) {
        this.imgList = this.imgList1;
        this.dataList = data.wen;
        this.nav = 1;
      } else {
        this.imgList = this.imgList2;
        this.dataList = data.li;
        this.nav = 2;
      }
    } else {
      this.imgList = this.imgList1;
      this.dataList = data.wen;
      this.nav = 1;
    }
  },

  methods: {
    change(e) {
      this.nav = e;
      if (e == 1) {
        this.imgList = this.imgList1;
        this.dataList = data.wen;
      } else {
        this.imgList = this.imgList2;
        this.dataList = data.li;
      }
    },
  },
};
</script>

<style scoped lang='less'>
.title {
  position: relative;
  .nav {
    position: absolute;
    top: 1px;
    left: 230px;
    span {
      margin-left: 50px;
    }
  }
  .more {
    position: absolute;
    top: 1px;
    right: 10px;
  }
}
.hove {
  color: orange;
}
.content {
  .college {
    box-shadow: 1px 2px 1px #f3f3f3;
    position: absolute;
    top: 0;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .piece {
      // padding: 5px 10px;
      width: 261px;
      height: 189px;
      border: 1px solid #f3f3f3;
      box-shadow: 1px 2px 1px #f3f3f3;
      margin-bottom: 10px;
      margin-left: 10px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .school {
    text-align: center;
    font-size: 12px;
    color: gray;
    z-index: 1;
  }
  .name {
    margin-bottom: 8px;
    margin-top: 23px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: black;
    z-index: 1;
  }
  .t_img {
    position: absolute;
    top: 0px;
  }
}
a {
  color: gray;
}
</style>
