var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_vm._v("热门院校 "),_c('div',{staticClass:"nav"},[_c('span',{class:{hove:_vm.nav == 1},on:{"click":function($event){return _vm.change(1)}}},[_vm._v("省内")]),_vm._v(" "),_c('span',{class:{hove:_vm.nav == 2},on:{"click":function($event){return _vm.change(2)}}},[_vm._v("省外")])]),_c('div',{staticClass:"more"},[_c('router-link',{attrs:{"to":"/lookData"}},[_vm._v("更多 >")])],1)]),_c('el-divider'),_c('div',{staticClass:"content"},[_c('el-row',[(_vm.nav == 1)?_c('el-col',{attrs:{"span":5}},[_c('el-row',{staticClass:"name",staticStyle:{"margin-top":"50px"}},[_vm._v(" 吉林大学 ")]),_c('router-link',{attrs:{"to":{
    path: '/College',
    query: { cid: 659, sid:6100, degree: 0},
  }}},[_c('img',{staticClass:"college",attrs:{"src":require("@/assets/img/home/college2.png"),"alt":""}})])],1):_c('el-col',{attrs:{"span":5}},[_c('router-link',{attrs:{"to":{
    path: '/College',
    query: { cid: 1122, sid:1145, degree: 0},
  }}},[_c('el-row',{staticClass:"name",staticStyle:{"margin-top":"50px"}},[_vm._v(" 吉林大学 ")]),_c('el-row',{staticClass:"school",staticStyle:{"margin-top":"30px"}},[_vm._v(" 招生代号:1145 ")]),_c('img',{staticClass:"college",attrs:{"src":require("@/assets/img/home/college.png"),"alt":""}})],1)],1),_c('el-col',{attrs:{"span":18,"offset":1}},[_c('div',{staticClass:"list"},_vm._l((_vm.dataList),function(item,i){return _c('div',{key:i,staticClass:"piece"},[_c('el-row',{staticClass:"school",attrs:{"type":"flex","align":"middle"}},[_c('el-col',{staticClass:"logo",attrs:{"span":6}},[_c('img',{attrs:{"src":item.logo,"alt":""}})]),_c('el-col',{attrs:{"offset":2,"span":16}},[_c('el-row',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('el-row',{staticClass:"school_id"},[_vm._v("在豫招生代号:"+_vm._s(item.school_id))]),_c('el-row',{staticClass:"type_"},[_c('el-col',{staticClass:"border",attrs:{"span":8}},[_vm._v(" "+_vm._s(item.school_type)+" ")]),_c('el-col',{attrs:{"offset":5,"span":11}},[_c('router-link',{attrs:{"to":{
    path: '/College',
    query: { cid: item.id, sid: item.school_id, degree: item.degree },
  }}},[_vm._v("查看院校>")])],1)],1)],1)],1)],1)}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }