module.exports = {
  'wen': [
    {
      "specialty_code": "050101",
      "name": "汉语言文学",
      "is_wenli": "文科",
      "jihua": "1026",
      "baokao": "3248",
      "luqu": "1038",
      "批次": "本科一批"
    },
    {
      "specialty_code": "050201",
      "name": "英语",
      "is_wenli": "文科",
      "jihua": "979",
      "baokao": "1161",
      "luqu": "982",
      "批次": "本科一批"
    },
    {
      "specialty_code": "120203",
      "name": "会计学",
      "is_wenli": "文科",
      "jihua": "651",
      "baokao": "963",
      "luqu": "658",
      "批次": "本科一批"
    },
    {
      "specialty_code": "0501",
      "name": "中国语言文学类",
      "is_wenli": "文科",
      "jihua": "311",
      "baokao": "832",
      "luqu": "322",
      "批次": "本科一批"
    },
    {
      "specialty_code": "0201",
      "name": "经济学类",
      "is_wenli": "文科",
      "jihua": "412",
      "baokao": "830",
      "luqu": "419",
      "批次": "本科一批"
    },
    {
      "specialty_code": "0503",
      "name": "新闻传播学类",
      "is_wenli": "文科",
      "jihua": "344",
      "baokao": "729",
      "luqu": "341",
      "批次": "本科一批"
    },
  ],
  'li': [
    {
      "specialty_code": "080901",
      "name": "计算机科学与技术",
      "is_wenli": "理科",
      "jihua": "2140",
      "baokao": "8616",
      "luqu": "2157",
      "pici": "本科一批"
    },
    {
      "specialty_code": "080902",
      "name": "软件工程",
      "is_wenli": "理科",
      "jihua": "2384",
      "baokao": "5720",
      "luqu": "2388",
      "pici": "本科一批"
    },
    {
      "specialty_code": "100201K",
      "name": "临床医学",
      "is_wenli": "理科",
      "jihua": "2208",
      "baokao": "5712",
      "luqu": "2207",
      "pici": "本科一批"
    },
    {
      "specialty_code": "080601",
      "name": "电气工程及其自动化",
      "is_wenli": "理科",
      "jihua": "1390",
      "baokao": "4911",
      "luqu": "1410",
      "pici": "本科一批"
    },
    {
      "specialty_code": "00600",
      "name": "工科试验班",
      "is_wenli": "理科",
      "jihua": "1503",
      "baokao": "3641",
      "luqu": "1511",
      "pici": "本科一批"
    },
    {
      "specialty_code": "0807",
      "name": "电子信息类",
      "is_wenli": "理科",
      "jihua": "1806",
      "baokao": "3473",
      "luqu": "1857",
      "pici": "本科一批"
    },
  ]
}