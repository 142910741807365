<template>
  <div class="main">
    <div class="title">高考工具</div>
    <el-divider></el-divider>
    <div class="tool">
      <div @click="toolClick(0)">
        <span class="span_1"></span>
        查大学
      </div>
      <div @click="toolClick(1)">
        <span class="span_2"></span>
        查专业
      </div>
      <div @click="toolClick(2)">
        <span class="span_3"></span>
        分数线
      </div>
      <div @click="toolClick(3)">
        <span class="span_4"></span>
        省控线
      </div>
      <div @click="toolClick(4)">
        <span class="span_5"></span>
        生涯测评
      </div>
      <div @click="toolClick(5)">
        <span class="span_6"></span>
        模拟填报
      </div>
      <div @click="toolClick(6)">
        <span class="span_7"></span>
        新高考选科
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",



  methods: {
    toolClick(e) {
      switch (e) {
        case 0:
          this.$router.push("lookCollege");
          return;
        case 1:
          this.$router.push("lookSpecialty");
          return;
        case 3:
          this.$router.push("provinceLine");
          return;
        case 5:
          this.$router.push("volunteer");
          return;
        case 6:
          this.$router.push("newChooseSubject");
      }
    },
  },
};
</script>

<style scoped lang='less'>
.tool {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  text-align: center;

  div {
    height: 160px;
    width: 150px;

    &:hover {
      border-radius: 6px;
      box-shadow: 4px 4px 10px #eeeeff, 4px -4px 10px #eeeeff,
        -4px 4px 10px #eeeeff, -4px -4px 10px #eeeeff;
      overflow: hidden;
      transition: all 0.3s;
      transform: scale(1.1);
    }

    span {
      display: block;
      height: 100px;
      width: 72px;
      margin: 21px 36px 0 44px;
    }

    .span_1 {
      background: url("../../../assets/img/home/home-bg.png") -450px -250px;
    }

    .span_2 {
      background: url("../../../assets/img/home/home-bg.png") -150px -250px;
    }

    .span_3 {
      background: url("../../../assets/img/home/home-bg.png") -600px -250px;
    }

    .span_4 {
      background: url("../../../assets/img/home/home-bg.png") -750px -250px;
    }

    .span_5 {
      background: url("../../../assets/img/home/home-bg.png") -900px -250px;
    }

    .span_6 {
      background: url("../../../assets/img/home/home-bg.png") -300px -250px;
    }

    .span_7 {
      background: url("../../../assets/img/home/shenhe.png") no-repeat;
    }
  }
}
</style>
