var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_vm._v("热门专业 "),_c('div',{staticClass:"more"},[_c('router-link',{attrs:{"to":"/lookData"}},[_vm._v("更多 >")])],1)]),_c('el-divider'),_c('div',{staticClass:"content"},[_c('el-row',[(_vm.nav == 1)?_c('el-col',{attrs:{"span":5}},[_c('el-row',{staticClass:"name",staticStyle:{"margin-top":"50px"}},[_vm._v(" 法学 ")]),_c('el-row',{staticClass:"school",staticStyle:{"margin-top":"30px"}},[_vm._v(" 报考人数：2329人 ")]),_c('router-link',{attrs:{"to":{
     path: '/specialtyInfo',
              query: { sid: '030101K', degree: 0 },
  }}},[_c('img',{staticClass:"college",attrs:{"src":require("@/assets/img/home/major.png"),"alt":""}})])],1):_c('el-col',{attrs:{"span":5}},[_c('el-row',{staticClass:"name",staticStyle:{"margin-top":"50px"}},[_vm._v(" 计算机类 ")]),_c('el-row',{staticClass:"school",staticStyle:{"margin-top":"30px"}},[_vm._v(" 报考人数：8665人 ")]),_c('router-link',{attrs:{"to":{
              path: '/specialtyInfo',
              query: { sid: '080901', degree: 0 },
            }}},[_c('img',{staticClass:"college",attrs:{"src":require("@/assets/img/home/maj0.jpg"),"alt":""}})])],1),_c('el-col',{attrs:{"span":18,"offset":1}},[_c('div',{staticClass:"list"},_vm._l((_vm.dataList),function(item,i){return _c('div',{key:i,staticClass:"piece"},[_c('router-link',{attrs:{"to":{
              path: '/specialtyInfo',
              query: { sid: item.specialty_code, degree: 0 },
            }}},[_c('el-row',{staticClass:"name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('el-row',{staticClass:"school"},[_vm._v(" 报考人数："+_vm._s(item.baokao)+"人 ")]),_c('img',{staticClass:"t_img",attrs:{"src":_vm.imgList[i]['img']}})],1)],1)}),0)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }