<template>
  <div class="main">
    <div class="title">热门院校
      <div class="nav"> <span @click="change(1)" :class="{hove:nav == 1}">省内</span> <span @click="change(2)" :class="{hove:nav == 2}">省外</span></div>
      <div class="more">
        <router-link to='/lookData'>更多 ></router-link>
      </div>
    </div>
    <el-divider></el-divider>

    <div class="content">
      <el-row>
        <el-col :span='5' v-if="nav == 1">
          <el-row class="name" style="margin-top:50px">
            吉林大学
          </el-row>
          <router-link :to="{
      path: '/College',
      query: { cid: 659, sid:6100, degree: 0},
    }"> <img class="college" src="@/assets/img/home/college2.png" alt=""></router-link>
        </el-col>

        <el-col :span='5' v-else>
          <router-link :to="{
      path: '/College',
      query: { cid: 1122, sid:1145, degree: 0},
    }">
            <el-row class="name" style="margin-top:50px">
              吉林大学
            </el-row>
            <el-row class="school" style="margin-top:30px">
              招生代号:1145
            </el-row>

            <img class="college" src="@/assets/img/home/college.png" alt="">
          </router-link>
        </el-col>
        <el-col :span='18' :offset="1">
          <div class="list">
            <div v-for="(item,i) in dataList" :key="i" class="piece">
              <el-row class="school" type="flex" align="middle">
                <el-col :span="6" class="logo"><img :src="item.logo" alt=""></el-col>
                <el-col :offset="2" :span="16">
                  <el-row class="name">{{item.name}}</el-row>
                  <el-row class="school_id">在豫招生代号:{{item.school_id}}</el-row>
                  <el-row class="type_">
                    <el-col :span="8" class="border">
                      {{item.school_type}}
                    </el-col>
                    <el-col :offset="5" :span="11">
                      <router-link :to="{
      path: '/College',
      query: { cid: item.id, sid: item.school_id, degree: item.degree },
    }">查看院校></router-link>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import data from "@/assets/data/hotCollege.js";
export default {
  name: "",
  data() {
    return {
      dataList: [],
      tableData: [],
      nav: 1,
    };
  },
  created() {
    this.tableData = data.li;
    this.dataList = this.tableData.shengnei;
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    change(e) {
      this.nav = e;
      if (e == 1) {
        this.dataList = this.tableData.shengnei;
      } else {
        this.dataList = this.tableData.shengwai;
      }
    },
  },
};
</script>

<style scoped lang='less'>
.hove {
  color: orange;
}
.title {
  position: relative;
  .nav {
    position: absolute;
    top: 1px;
    left: 230px;
    span {
      margin-left: 50px;
    }
  }
  .more {
    position: absolute;
    top: 1px;
    right: 10px;
  }
}
.content {
  .college {
    position: absolute;
    top: 0;
    box-shadow: 1px 2px 1px #f3f3f3;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .piece {
      padding: 5px 10px;
      width: 242px;
      height: 101px;
      border: 1px solid #f3f3f3;
      box-shadow: 1px 2px 1px #f3f3f3;
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
  .school {
    font-size: 13px;
    color: gray;
    .name {
      font-size: 17px;
      font-weight: bold;
      color: black;
      margin-top: 10px;
      margin-bottom: 7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .logo {
      text-align: center;
      img {
        width: 65px;
        margin-top: 12px;
      }
    }
    .type_ {
      margin-top: 20px;
      .border {
        border: 1px solid gray;
        text-align: center;
      }
    }
  }
}
a {
  color: gray;
}
</style>
