<template>
  <div class="home">
    <banner />
    <div style="width: 1200px; margin: 0 auto 25px; background: white">
      <!--        个人中心-->

      <div class="info">
        <el-row class="info-data" type="flex" align="bottom">
          <el-col :span="13">
            <div class="icon_h">
              <img src="@/assets/img/home/ai-icon.png" alt="">
              <span class="title-icon">新高考选科及模拟志愿填报</span>
            </div>
          </el-col>
          <el-col :span="3" :offset="11">
            <img src="@/assets/img/home/position.png" alt="" class="position-img">
            <div class="position">河南省</div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 10px;">
          <el-col :span="8" :offset="2">
            <div style="margin-bottom: 20px;">首选科目(2选1)</div>
            <el-checkbox-group v-model="subject_list" :max="1" size="medium" disabled @change="getSubject">
              <el-checkbox :label="4">历史</el-checkbox>
              <el-checkbox :label="1">物理</el-checkbox>
            </el-checkbox-group>
            <div style="margin-top: 20px;">
              成绩 <el-input v-model="score" size="mini" type="number" style="width: 150px;margin-left: 20px;"
                placeholder="输入分数">
                <template slot="append">分</template>
              </el-input>
            </div>
          </el-col>

          <el-col :span="14">
            <div style="margin-bottom: 20px;">再选科目(4选2)
              <div class="remarks">
                注:必须先选择首选科目，才能选择再选科目，且再选科目至多只能选择2项
              </div>
            </div>
            <el-checkbox-group v-model="other_subject" @change="changeOtherSubject()" :max="2">
              <el-checkbox :label="'2'">化学</el-checkbox>
              <el-checkbox :label="'3'">生物</el-checkbox>
              <el-checkbox :label="'6'">政治</el-checkbox>
              <el-checkbox :label="'5'">地理</el-checkbox>
            </el-checkbox-group>
            <div style="margin-top: 20px;">
              位次 <el-input v-model="ranking" size="mini" type="number" style="width: 150px;margin-left: 20px;"
                placeholder="输入位次">
                <template slot="append">位</template>
              </el-input>
              <div class="remarks">
                注:分数对应2024年排名区间为{{ ranking ? ranking : '----' }}名，可手动输入
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 30px;">
          <el-col :span="3" :offset="14">
            <el-button type="primary" @click="toXuanke()">智能选科(去选科)</el-button>
          </el-col>
          <el-col :span="5" :offset="2">
            <el-button type="primary" @click="toTianbao()">智能填报(去填报)</el-button>
          </el-col>
        </el-row>

      </div>

      <!--倒计时-->
      <countdown />
    </div>
    <recommend />
    <tools />
    <news />
    <hotCollege />
    <hotMajor />
    <core />
    <div style="height:1px">
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import recommend from "./components/recommend";
import news from "./components/news";
import banner from "./components/banner";
import tools from "./components/tools";
import core from "./components/core";
import countdown from "./components/countdown";
import hotCollege from "./components/hotCollege";
import hotMajor from "./components/hotMajor";

export default {
  computed: {
    ...mapState(["userInfo"]),
  },
  name: "index",
  components: {
    recommend,
    news,
    tools,
    banner,
    core,
    countdown,
    hotCollege,
    hotMajor,
  },
  data() {
    return {
      batch: "1",
      is_wenli: "1",
      score: "",
      ranking: "",
      NumList: [],
      other_subject: [],
      subject_list: []
    };
  },
  mounted() {
    console.log(this.userInfo)
    if (this.userInfo) {
      this.score = this.userInfo.score;
      this.is_wenli = this.userInfo.is_wenli;
      this.ranking = this.userInfo.ranking;
      this.subject_list = [this.userInfo.subject];
      console.log(this.subject_list)
      this.other_subject = JSON.parse(this.userInfo.other_subject)
      console.log(JSON.parse(this.userInfo.other_subject))
    }
  },
  methods: {
    changeOtherSubject() {
      this.other_subject = JSON.parse(this.userInfo.other_subject)
      this.$fecth.post("user_info/updateUserInfo", this.userInfo).then((res) => {
        if (res) {
          this.$message({
            showClose: true,
            message: "提交成功！",
            type: "success",
          });
          localStorage.setItem("UserInfo", JSON.stringify(res));
          this.setInfo(res);
          // 回到首页
          let redirect = this.$route.query.redirect
          if (redirect) {
            setTimeout(() => {
              this.$router.push('/');
            }, 1000);
          } else {
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          }
        }
      });



      console.log(this.other_subject)
    },

    getSubject() {
      console.log(this.subject_list)
      if (this.subject_list.length > 0) {

        this.subject = this.subject_list[0]
        if (this.subject == 4) {
          this.is_wenli = 1
        } else {
          this.is_wenli = 2
        }
        this.getRanking()
      }

    },
    //获取学校数量
    getRanking() {
      let data = {
        is_wenli: this.is_wenli,
        score: this.score,
      };
      this.$fecth.post("score/getRanking", data).then((res) => {
        this.ranking = res;
      });
    },
    toXuanke() {
      this.$router.push(
        '/newChooseSubject/chooseSpecialty'
      )
    },
    toTianbao() {
      this.$router.push(
        '/volunteer'
      )
    }
  },
};
</script>

<style lang="less" scoped>
.home {
  background: #f4f4f4;

  .main {
    width: 1100px;
    margin: 0 auto 25px;
    background: white;
    padding: 25px 50px;
    font-size: 18px;
  }

}

.info {
  display: inline-block;
  width: 886px;
  font-size: 18px;

  .position-img {
    display: inline;
    vertical-align: middle;
    width: 25px;
  }

  .position {
    color: #0a98ff;
    display: inline;
    vertical-align: middle;
  }

  .info-data {
    font-size: 18px;
    padding: 34px 50px 10px 50px;

    .icon_h {
      height: 40px;
      background: linear-gradient(-135deg, transparent 30px, #2AA0FA 0%, #0D5FFF 100%);

      img {
        // height: 40px;
        vertical-align: middle;
        width: 25px;
        margin-right: 10px;
        margin-left: 20px
      }

      .title-icon {
        padding: 1px 8px;
        color: white;
        vertical-align: middle;
        line-height: 40px;
      }

      border-radius: 10px;
    }
  }

  .remarks {
    color: gray;
    display: inline;
    font-size: 11px;
    margin-left: 5px;
  }

}
</style>

<style lang="less">
.home {
  .el-divider--horizontal {
    margin: 11px 0;
  }

  .info {
    .el-radio__label {
      font-size: 18px;
    }
  }
}
</style>