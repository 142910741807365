<template>
  <div class="main">
    <div class="core">
      <div class="foot">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  
  
  
  
};
</script>

<style scoped lang='less'>
.core {
  .foot {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    div {
      width: 265px;
      height: 100px;
      background: red;
    }

    div:nth-child(1) {
      background: url("../../../assets/img/home/home-foot.jpg") no-repeat -23px -18px;
    }

    div:nth-child(2) {
      background: url("../../../assets/img/home/home-foot.jpg") no-repeat -296px -18px;
    }

    div:nth-child(3) {
      background: url("../../../assets/img/home/home-foot.jpg") no-repeat -571px -18px;
    }

    div:nth-child(4) {
      background: url("../../../assets/img/home/home-foot.jpg") no-repeat -842px -18px;
    }
  }
}
</style>
