module.exports = {
  'wen': {
    'shengnei': [
      {
        "id": 663,
        "school_id": "6030",
        "name": "河南师范大学",
        "plan_num": 1456,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191113/3bf72492ae534de1fb1082784c130ace.jpg",
        "school_type_id": 22,
        "school_type": "师范类",
        "degree": 0
      },
      {
        "id": 664,
        "school_id": "6080",
        "name": "河南财经政法大学",
        "plan_num": 970,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191113/4708570cd6a08c7a78355b6b1685022d.jpg",
        "school_type_id": 17,
        "school_type": "财经类",
        "degree": 0
      },
      {
        "id": 674,
        "school_id": "6015",
        "name": "河南中医药大学",
        "plan_num": 736,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191114/bde2ebbc220cbab11fac05a8fc6f3e09.jpg",
        "school_type_id": 24,
        "school_type": "医药类",
        "degree": 0
      },
      {
        "id": 658,
        "school_id": "6005",
        "name": "河南大学",
        "plan_num": 714,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191113/10ce4f0064a37264b9629256337346cb.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 658,
        "school_id": "6005",
        "name": "河南大学",
        "plan_num": 714,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191113/10ce4f0064a37264b9629256337346cb.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 662,
        "school_id": "6010",
        "name": "河南农业大学",
        "plan_num": 500,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191113/346beb0d58e874c8eef2b747e15695a0.jpg",
        "school_type_id": 19,
        "school_type": "农林类",
        "degree": 0
      }
    ],
    'shengwai': [
      {
        "id": 1541,
        "school_id": "5010",
        "name": "海南师范大学",
        "plan_num": 110,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191123/201a1f1ab886f31b54b05822a537acaa.jpg",
        "school_type_id": 22,
        "school_type": "师范类",
        "degree": 0
      },
      {
        "id": 1344,
        "school_id": "1205",
        "name": "中南财经政法大学",
        "plan_num": 105,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191122/61a9e2611b5ca6c5ef567f8c53b33247.jpg",
        "school_type_id": 17,
        "school_type": "财经类",
        "degree": 0
      },
      {
        "id": 873,
        "school_id": "2390",
        "name": "上海师范大学",
        "plan_num": 99,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191114/a1230a9b3edc5272d0cf14aae86c5f83.jpg",
        "school_type_id": 22,
        "school_type": "师范类",
        "degree": 0
      },
      {
        "id": 1338,
        "school_id": "1180",
        "name": "武汉大学",
        "plan_num": 91,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191122/4f6a13d70f319505fe6ac8508acc8845.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 1028,
        "school_id": "3240",
        "name": "天津商业大学",
        "plan_num": 87,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191116/ec3e433e5afbf02b48eb7e2786372f46.jpg",
        "school_type_id": 17,
        "school_type": "财经类",
        "degree": 0
      },
      {
        "id": 1990,
        "school_id": "2120",
        "name": "新疆大学",
        "plan_num": 72,
        "is_wenli": "文科",
        "logo": "https://img.bkgaoshou.com/img/20191203/5c181a0e7ab3d689da4b5d30a84455e2.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      }
    ]
  },
  'li': {
    'shengnei': [
      {
        "id": 662,
        "school_id": "6010",
        "name": "河南农业大学",
        "plan_num": 4096,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191113/346beb0d58e874c8eef2b747e15695a0.jpg",
        "school_type_id": 19,
        "school_type": "农林类",
        "degree": 0
      },
      {
        "id": 661,
        "school_id": "6085",
        "name": "河南科技大学",
        "plan_num": 3236,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191113/354573e8d6d3fb7def1e9127edd5ac99.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 657,
        "school_id": "6000",
        "name": "郑州大学",
        "plan_num": 3067,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191113/5eca9fe11864b83987886cdf8de0786c.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 660,
        "school_id": "6105",
        "name": "河南工业大学",
        "plan_num": 2745,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191113/c476364baca94f827260fce74e137033.jpg",
        "school_type_id": 18,
        "school_type": "理工类",
        "degree": 0
      },
      {
        "id": 668,
        "school_id": "6090",
        "name": "郑州航空工业管理学院",
        "plan_num": 2738,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191113/7f762269057cb0497a7cc156d4a95ad8.png",
        "school_type_id": 17,
        "school_type": "财经类",
        "degree": 0
      },
      {
        "id": 665,
        "school_id": "6095",
        "name": "华北水利水电大学",
        "plan_num": 2543,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191113/734ec1c1d5673d3d7b468ddc73005e77.jpg",
        "school_type_id": 18,
        "school_type": "理工类",
        "degree": 0
      }
    ],
    'shengwai': [
      {
        "id": 1341,
        "school_id": "1315",
        "name": "武汉理工大学",
        "plan_num": 410,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191122/b4d18f34252c7f0741769daef040b1f3.jpg",
        "school_type_id": 18,
        "school_type": "理工类",
        "degree": 0
      },
      {
        "id": 1338,
        "school_id": "1180",
        "name": "武汉大学",
        "plan_num": 407,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191122/4f6a13d70f319505fe6ac8508acc8845.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 1339,
        "school_id": "1140",
        "name": "华中科技大学",
        "plan_num": 386,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191122/6b839b03ac2a9883cea8b0784052993c.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 1990,
        "school_id": "2120",
        "name": "新疆大学",
        "plan_num": 380,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191203/5c181a0e7ab3d689da4b5d30a84455e2.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      },
      {
        "id": 1028,
        "school_id": "3240",
        "name": "天津商业大学",
        "plan_num": 359,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191116/ec3e433e5afbf02b48eb7e2786372f46.jpg",
        "school_type_id": 17,
        "school_type": "财经类",
        "degree": 0
      },
      {
        "id": 1403,
        "school_id": "1210",
        "name": "中南大学",
        "plan_num": 352,
        "is_wenli": "理科",
        "logo": "https://img.bkgaoshou.com/img/20191204/c2409cbec33eb1ab84e7dad912144492.jpg",
        "school_type_id": 20,
        "school_type": "综合类",
        "degree": 0
      }
    ]
  }

}